<template>
  <div>
    <b-card>
      <b-table responsive="sm" :items="items" :fields="fields">
        <template #cell(user)="data">
          {{ data.value.name }}
        </template>
        <template #cell(amount)="data">
          {{ getPrice(data.value) }}
        </template>
        <template #cell(due_payment)="data">
          <div v-if="data.value === 0">
            <span class="text-danger font-weight-bold">Paid Fully</span>
          </div>
          <div v-else>{{ getPrice(data.value) }}</div>
        </template>
        <template #cell(total_payment)="data">
          {{ getPrice(data.value) }}
        </template>
        <template #cell(payment_date)="data">
          {{ momentFormat(data.value, "YYYY-MM-DD") }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BModal,
  BCard,
  BTable,
  BBadge,
  BButton,
  BCol,
  BRow,
  BCardText,
  BAvatar,
  BLink,
  BContainer,
} from "bootstrap-vue";
import paymentApi from "@/Api/Modules/payment";

export default {
  name: "PaymentTable",
  components: {
    BCard,
    BModal,
    BButton,
    BCol,
    BBadge,
    BAvatar,
    BTable,
    BRow,
    BContainer,
    BCardText,
    BLink,
  },
  data() {
    return {
      show: false,
      selectedItem: {},
      fields: [
        {
          key: "user",
          label: "Paid User",
          sortable: true,
        },
        {
          key: "paid_month",
          label: "Paid Months",
          sortable: true,
        },
        {
          key: "amount",
          label: "Paid Amount",
          sortable: true,
        },
        {
          key: "due_payment",
          label: "Due Payment",
          sortable: true,
        },

        {
          key: "total_payment",
          label: "Total Payment",
          sortable: true,
        },

        {
          key: "payment_date",
          label: "Payment Date",
          sortable: true,
        },
      ],
      items: [],
    };
  },
  async created() {
    await this.AllPayments();
  },

  methods: {
    setCellPadding(value, key, item) {
      // Add a custom class to table cells based on your requirements
      return "custom-cell-padding";
    },
    async AllPayments() {
      await this.$vs.loading({
        scale: 0.8,
      });
      const res = await paymentApi.allPayments().catch(() => {
        this.$vs.loading.close();
      });
      this.items = res.data.data;
      this.$vs.loading.close();
    },
  },
};
</script>

<style>
.custom-cell-padding {
  height: 70px; /* Set your desired padding value */
}
</style>
